














































































































































































































































































































import ImageUpload from '@simpl/core/components/uploader/ImageUpload.vue'
import ActiveDomainTags from '@simpl/core/mixins/apollo/ActiveDomainTags'
import TopicTags from '@simpl/core/mixins/apollo/TopicTags'
import StringToColor from '@simpl/core/mixins/utils/StringToColor'
import TagSelectFilter from '@simpl/core/mixins/utils/TagSelectFilter'

import { EditableLearningPath } from '../types'
import { Tag } from '@simpl/core/types/graphql'
import mixins from 'vue-typed-mixins'
import { getTextForUserLanguage, getTextForLanguage } from '@simpl/core/utils'
import FileUpload from '@simpl/core/mixins/apollo/FileUpload'
import EditKeyvisual from '../components/EditKeyvisual.vue'
import DomainMarketSelection from '@simpl/core/components/DomainMarketSelection.vue'
import { getKeyvisual, updateKeyvisual, removeLanguageKeyvisual } from '../../utils/keyvisual'
// @ts-ignore
import ShareLinkDialog from '../components/ShareLinkDialog.vue'
import GenericKeyvisuals from '../mixins/GenericKeyvisuals'
import { LearningPathEditUpdates } from './LearningPathEdit.vue'
import { TagWithName } from '@simpl/core/types/extended-types'

export default mixins(
  ActiveDomainTags,
  TopicTags,
  TagSelectFilter,
  StringToColor,
  FileUpload,
  GenericKeyvisuals
).extend({
  name: 'LearningPathEditBasic',

  components: { ImageUpload, EditKeyvisual, DomainMarketSelection },

  props: {
    learningPath: Object as () => EditableLearningPath,
    updates: Object as () => LearningPathEditUpdates,
    languageTags: Array as () => TagWithName[],
    userGroupTags: Array as () => TagWithName[],
    userGroupTagIds: Array
  },

  data () {
    return {
      keyVisualBlob: null as Blob | null,
      keyVisualUrl: undefined as string | null | undefined,
      keyVisualId: null as number | null,
      showAdvancedSettings: false as Boolean,
      mandatoryLevels: ['0-compulsive', '1-recommended', '2-optional'],
      priorityLevels: [{
        text: this.$t('learningPath.priorityLevels.none'),
        value: 0
      }, {
        text: this.$t('learningPath.priorityLevels.medium'),
        value: 50
      }, {
        text: this.$t('learningPath.priorityLevels.high'),
        value: 99
      }],
      activeSettings: null! as string,
      showLanguageSelect: false,
      masterLanguageId: null! as string
    }
  },

  computed: {
    selectedUserGroupTags (): string[] {
      return this.learningPath.tagIds!.filter((id: string) => this.userGroupTagIds.indexOf(id) > -1)
    },
    masterLanguageTag: {
      get (): Tag | void {
        return this.languageTags?.find((tag: Record<string, any>) => tag?.identifier === this.learningPath.languagecode)
      },
      set (v: Record<string, any>) {
        this.learningPath.languagecode = v.identifier
      }
    },
    masterLanguageKeyVisual (): any {
      return getKeyvisual(this.learningPath) || null
    },
    name: {
      get (): string {
        const updates = this.updates.lang[this.learningPath.languagecode!]
        // eslint-disable-next-line
        const hasUpdates = typeof updates?.display_name === 'string'

        // eslint-disable-next-line
        return hasUpdates ? updates?.display_name : getTextForLanguage(this.learningPath, this.learningPath.languagecode!)
      },
      set (v: string) {
        this.updateLanguage(v, this.learningPath.languagecode!, 'display_name')
      }
    },
    description: {
      get (): string {
        const updates = this.updates.lang[this.learningPath.languagecode!]
        const hasUpdates = typeof updates?.description === 'string'

        return hasUpdates ? updates.description : getTextForLanguage(this.learningPath, this.learningPath.languagecode!, 'description')
      },
      set (v: string) {
        this.updateLanguage(v, this.learningPath.languagecode!, 'description')
      }
    },
    learningPathLanguages (): Record<string, any>[] | null {
      const languageTags = this.languageTags!.filter(tag => this.learningPath.tagIds!.indexOf(tag.id) > -1)

      return languageTags.map(tag => {
        const languagecode = tag!.identifier
        const updates = this.updates.lang[languagecode]

        return {
          ...tag,
          keyVisualUrl: getKeyvisual(this.learningPath, languagecode),
          // eslint-disable-next-line
          name: typeof updates?.display_name === 'string'
            ? updates.display_name
            : getTextForLanguage(this.learningPath, languagecode),
          description: typeof updates?.description === 'string'
            ? updates.description
            : getTextForLanguage(this.learningPath, languagecode, 'description')
        }
      })
    }
  },

  watch: {
    masterLanguageKeyVisual (v) {
      if (!v) {
        const genericKeyvisual = this.getRandomGenericKeyvisual()
        updateKeyvisual(this.learningPath, genericKeyvisual)

        return
      }

      this.$set(this.updates, 'masterKeyVisualUrl', v.url)
    },
    genericKeyvisuals () {
      if (!this.masterLanguageKeyVisual) {
        updateKeyvisual(this.learningPath, this.getRandomGenericKeyvisual())
      }
    }
  },

  methods: {
    getTextForUserLanguage,
    getKeyvisual,
    updateKeyvisual,
    removeLanguageKeyvisual,

    async setAsMasterLanguage (language: Record<string, any>) {
      const confirmed = await this.$confirm({
        message: this.$t('run.notifications.setMasterLanguageConfirm',
                         {
                           current: getTextForUserLanguage(this.masterLanguageTag),
                           new: getTextForUserLanguage(language)
                         }),
        buttons: [{
          text: this.$t('core.global.yes'),
          type: 'outlined',
          answer: true
        }, {
          text: this.$t('core.global.no'),
          answer: false
        }]
      })

      if (confirmed) {
        this.masterLanguageTag = language
      }
    },

    updateLanguage (text: string, language: string, identifier?: string) {
      if (!this.updates.lang[language]) {
        this.$set(this.updates.lang, language, {})
      }

      this.$set(this.updates.lang[language], identifier || 'display_name', text)
    },

    removeLanguage (language: Record<string, any>) {
      const index = this.learningPath.tagIds!.indexOf(language.id)
      this.learningPath.tagIds!.splice(index, 1)

      if (!this.updates.deletedLanguages) {
        this.$set(this.updates, 'deletedLanguages', [])
      }
      this.updates.deletedLanguages.push(language.identifier)

      removeLanguageKeyvisual(this.learningPath, language.identifier)
    },

    openSettings (name: string) {
      this.showAdvancedSettings = true
      this.activeSettings = name
    }
  }
})
