import Vue from 'vue'
import { LIST_TAGS } from '../../graphql'
import { getTextForUserLanguage } from '../../utils'
import { Query, Tag } from '../../types/graphql'
import { TagWithName } from '../../types/extended-types'

export default Vue.extend({
  data: () => ({
    userGroupTags: [] as TagWithName[]
  }),

  computed: {
    userGroupTagIds (): string[] {
      return this.userGroupTags.map((item: { id: string }) => item.id)
    }
  },

  apollo: {
    userGroupTags: {
      query: LIST_TAGS,

      fetchPolicy: 'cache-and-network',

      variables (): Record<string, any> {
        return {
          first: 9999,
          filter: {
            filterBy: [{
              name: 'identifier',
              values: ['usergroup']
            }]
          }
        }
      },

      update (result: Query): TagWithName[] {
        return result.tagCategories!.data[0]!.tags!.slice().map((r: Tag) => ({
          ...r,
          name: getTextForUserLanguage(r)
        }))
      },

      error (error: Error) {
        console.error(error)
      }
    }
  }
})
