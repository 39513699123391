import Vue from 'vue'
import { Run } from '../../types/graphql'
import { getLanguagecodeForTemporaryUserLanguage } from '../../utils/text'

export default Vue.extend({
  methods: {
    isFallbackLanguage (item: Run, temporaryLanguage: string): any {
      return temporaryLanguage && getLanguagecodeForTemporaryUserLanguage(item) === undefined ? item.languagecode : temporaryLanguage && getLanguagecodeForTemporaryUserLanguage(item) !== temporaryLanguage ? getLanguagecodeForTemporaryUserLanguage(item) : false
    }
  }
})
