var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"flex-grow-1 content-padding",attrs:{"tile":""}},[_c('Headline',{staticClass:"headline",attrs:{"color":"primary"}},[_c('span',[_vm._v(" "+_vm._s(_vm.headlineText)+" ")])]),_c('v-switch',{attrs:{"label":_vm.$t('core.action.consecutiveToggle')},model:{value:(_vm.isConsecutive),callback:function ($$v) {_vm.isConsecutive=$$v},expression:"isConsecutive"}}),_c('CoreSearchFilterBar',{staticClass:"mb-6 mr-1",attrs:{"search":false},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"can",rawName:"v-can",value:('module-create'),expression:"'module-create'"}],staticClass:"ml-4",attrs:{"color":"primary","fab":"","small":""},on:{"click":function($event){_vm.showRunList = true}}},on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('core.action.add')))])])]},proxy:true}])}),_c('v-fade-transition',{attrs:{"mode":"out-in"},on:{"after-enter":_vm.initSortableTable}},[_c('v-data-table',{key:"list",attrs:{"headers":_vm.headers,"items":_vm.learningPath.runs,"disable-sort":"","disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.drag-indicator",fn:function(){return [_c('v-icon',[_vm._v("mdi-drag-horizontal-variant")])]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.version ? ((_vm.getTextForTemporaryUserLanguage(item)) + " (" + (item.version) + ")") : _vm.getTextForTemporaryUserLanguage(item))+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"white--text",attrs:{"small":"","color":_vm.stringToColor(item.type)}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" "+_vm._s(item.type === 'event' ? 'mdi-calendar-check' : 'mdi-school')+" ")]),_vm._v(" "+_vm._s(_vm.$t(("run.types." + (item.type))))+" ")],1)]}},(!_vm.$vuetify.breakpoint.xs)?{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"actions"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"can",rawName:"v-can",value:('run-create'),expression:"'run-create'"}],staticClass:"mr-2",attrs:{"small":"","icon":"","to":("/run/" + (item.id) + "/basic"),"target":"_blank"}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-open-in-new ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('core.action.open')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-3",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.remove(item.id)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-minus-circle ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('core.action.remove')))])])],1)]}}:null],null,true)})],1),_c('v-navigation-drawer',{staticClass:"run-selection",attrs:{"absolute":"","temporary":"","right":"","width":"400"},model:{value:(_vm.showRunList),callback:function ($$v) {_vm.showRunList=$$v},expression:"showRunList"}},[_c('v-card',{staticClass:"fill-height"},[_c('v-list',[_c('v-subheader',[_vm._v(" "+_vm._s(_vm.$t('learningPath.contents.drawerHeadline', [_vm.headlineText]))+" "),_c('v-btn',{staticClass:"mt-0 mr-2",staticStyle:{"z-index":"6"},attrs:{"icon":"","fixed":"","right":""},nativeOn:{"click":function($event){_vm.showRunList = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-autocomplete',{staticClass:"flex-grow-0 ma-0 pb-0 pt-2 pr-6 pl-4",attrs:{"label":_vm.$t('user.global.userGroups'),"items":_vm.userGroupTags,"item-text":"name","item-value":"id","multiple":"","chips":"","fill-width":"","disabled":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index < 3)?_c('v-chip',{staticClass:"white--text",style:(index > 0 ? 'margin-left: -10px;' : ''),attrs:{"small":"","color":_vm.stringToColor(item.name)}},[_c('span',[_vm._v(_vm._s(item.name))])]):_vm._e(),(index === 3)?_c('span',{staticClass:"grey--text caption"},[_vm._v(_vm._s(("(+" + (_vm.selectedUserGroupTags.length - 3) + " " + (_vm.$t('core.global.others')) + ")")))]):_vm._e()]}}]),model:{value:(_vm.learningPath.tagIds),callback:function ($$v) {_vm.$set(_vm.learningPath, "tagIds", $$v)},expression:"learningPath.tagIds"}}),_c('v-autocomplete',{staticClass:"flex-grow-0 ma-0 pb-0 pt-2 pr-6 pl-4",staticStyle:{"min-width":"200px"},attrs:{"clearable":"","label":_vm.$t('run.global.topics'),"items":_vm.topicTags,"item-text":"name","item-value":"identifier"},model:{value:(_vm.selectedTopic),callback:function ($$v) {_vm.selectedTopic=$$v},expression:"selectedTopic"}}),_c('v-progress-linear',{attrs:{"active":_vm.loadingRuns > 0,"indeterminate":""}}),_c('v-list-item-group',{attrs:{"value":_vm.learningPathRunIds,"multiple":""}},_vm._l((_vm.displayedRuns),function(run){return _c('v-list-item',{key:run.id,attrs:{"value":run.id},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.toggleSelectedRuns(run.id)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"true-value":run.id},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.toggleSelectedRuns(run.id, toggle)}}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(run.version ? ((_vm.getTextForTemporaryUserLanguage(run)) + " (" + (run.version) + ")") : _vm.getTextForTemporaryUserLanguage(run))+" ")])],1),_c('v-list-item-content',{staticClass:"ml-4",staticStyle:{"flex":"0 1 auto"}},[_c('v-icon',{attrs:{"color":"grey","small":""}},[_vm._v(" "+_vm._s(run.type === 'event' ? 'mdi-calendar-check' : 'mdi-school')+" ")])],1),_c('v-list-item-action',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"icon":"","small":"","to":("/run/" + (run.id) + "/basic"),"target":"_blank"}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-open-in-new ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('core.action.open')))])])],1)]}}],null,true)})}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }