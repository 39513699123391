











































































































































import Vue from 'vue'
// @ts-ignore
import VSwitch from 'vuetify/lib/components/VSwitch'

export default Vue.extend({
  name: 'FilterView',

  components: {
    VSwitch: VSwitch as any
  },

  props: {
    value: Boolean,
    filterBy: Array as () => any[],
    filterOptions: Array as () => any[]
  },

  data () {
    return {
      filter: this.filterBy,
      dateRange: []
    }
  },

  computed: {
    show: {
      get (): boolean {
        return this.value
      },
      set (v: boolean): void {
        this.$emit('input', v)
      }
    }
  },

  watch: {
    dateRange (v) {
      const dateRangeOption = this.filterOptions.find(option => option.type === 'date-range')
      if (dateRangeOption) {
        if (dateRangeOption.props.format && dateRangeOption.props.format === 'datetime') {
          this.filter[dateRangeOption.model] = v.map((item: string, index: number) => {
            if (index === 0) {
              return item + ' 00:00:00'
            }
            if (index === 1) {
              return item + ' 23:59:59'
            }
            return item
          })
        } else {
          this.filter[dateRangeOption.model] = v
        }
      }
    }
  },

  methods: {
    applyFilter (): void {
      this.$emit('update:filter-by', this.remapFilter(this.filter))
      this.show = false
    },
    removeFilter (): void {
      this.filter = []
      this.dateRange = []
    },
    cancel (): void {
      this.filter = []
      this.dateRange = []
      this.show = false
    },
    toggleTrueSwitch (filterOption: number, switchValue: boolean): void {
      this.filter[filterOption] = switchValue || []
    },
    remapFilter (filter: Record<string, any>): object {
      return Object.keys(filter).reduce((result, selectedOption) => {
        const optionData = this.filterOptions.find(filterOption => filterOption.model === selectedOption)!

        const filterOption: Record<string, any> = {}

        const filterColumn = optionData.filterNull ? optionData.filterNull[filter[optionData.model]] : optionData.filterColumn

        filterOption.name = optionData.relationColumn ? `${optionData.model}:${optionData.relationColumn}` : optionData.model
        if (optionData.filterNull) {
          filterOption[filterColumn] = true
        } else {
          filterOption[filterColumn] = Array.isArray(filter[optionData.model]) ? filter[optionData.model] : [filter[optionData.model]]
        }

        if (filterOption[filterColumn].length || filterOption[filterColumn] === true) {
          result.push(filterOption)
        }

        return result
      }, [] as object[])
    }
  }
})
