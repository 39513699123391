import gql from 'graphql-tag'

export const FRAGMENT_LEARNING_PATH_LIST = gql`fragment LearningPathList on LearningPath {
    id
    identifier
    mandatory_level
    priority
    languagecode
    properties
    texts {
        id
        identifier
        languagecode
        text
    }
    files {
        id
        name
        languagecode
        url
        path
    }
    tags {
        category {
            id
            identifier
        }
        id
        identifier
        texts  {
            identifier
            languagecode
            text
        }
    }
    created_at
    updated_at
}`

export const LIST_LEARNING_PATHS = gql`query listLearningPaths (
    $filter: FilterInput!,
    $first: Int!,
    $page: Int,
    $orderBy: [OrderByClause!]
) {
    learningPaths (filter: $filter, first: $first, page: $page, orderBy: $orderBy) {
        data {
            ...LearningPathList
        },
        paginatorInfo {
            count
            currentPage
            hasMorePages
            lastPage
            perPage
            total
        }
    }
}${FRAGMENT_LEARNING_PATH_LIST}`

export const GET_LEARNING_PATH = gql`query GET_LEARNING_PATH ($id: ID) {
    learningPath (id: $id) {
        ...LearningPathList
        runs {
            id
            identifier
            version
            type
            tags {
                category {
                    id
                    identifier
                }
                id
                identifier
                texts  {
                    identifier
                    languagecode
                    text
                }
            }
            texts {
                identifier
                languagecode
                text
            }
            created_at
            updated_at
        }
    }
}${FRAGMENT_LEARNING_PATH_LIST}`

export const CREATE_LEARNING_PATH = gql`mutation CREATE_LEARNING_PATH ($data: NewLearningPathInput!) {
    createLearningPath (data: $data) {
        ...LearningPathList
        runs {
            id
            identifier
            type
            tags {
                category {
                    id
                    identifier
                }
                id
                identifier
                texts  {
                    identifier
                    languagecode
                    text
                }
            }
            texts {
                identifier
                languagecode
                text
            }
            created_at
            updated_at
        }
    }
}${FRAGMENT_LEARNING_PATH_LIST}`

export const UPDATE_LEARNING_PATH = gql`mutation UPDATE_LEARNING_PATH ($data: UpdateLearningPathInput!) {
    updateLearningPath (data: $data) {
        ...LearningPathList
        runs {
            id
            identifier
            type
            tags {
                category {
                    id
                    identifier
                }
                id
                identifier
                texts  {
                    identifier
                    languagecode
                    text
                }
            }
            texts {
                identifier
                languagecode
                text
            }
            created_at
            updated_at
        }
    }
}${FRAGMENT_LEARNING_PATH_LIST}`

export const DELETE_LEARNING_PATH = gql`mutation DELETE_LEARNING_PATH ($id: ID!) {
    deleteLearningPath(id: $id) { id }
}`
