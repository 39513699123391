var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-hover',{attrs:{"disabled":!(_vm.source && !_vm.cropSrc)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-img',{staticClass:"image-upload",style:({ backgroundColor: _vm.backgroundColor }),attrs:{"aspect-ratio":_vm.aspectRatio,"min-height":_vm.minHeight,"max-height":_vm.maxHeight,"height":_vm.height,"src":_vm.source,"lazy-src":_vm.lazySrc},on:{"dragenter":function($event){$event.preventDefault();_vm.dragOver += 1},"dragover":function($event){$event.preventDefault();},"dragleave":function($event){$event.preventDefault();_vm.dragOver -= 1},"drop":_vm.onDrop}},[_c('label',{ref:"label",staticClass:"image-upload__label d-flex justify-center align-center",attrs:{"for":_vm.inputId}},[_c('input',{ref:"input",staticClass:"image-upload__file",attrs:{"id":_vm.inputId,"type":"file","accept":_vm.accept},on:{"change":_vm.onInput}}),(!_vm.source)?_c('div',{staticClass:"text-center px-3"},[_c('img',{staticStyle:{"width":"100px"},attrs:{"src":require("../../assets/images/image.svg"),"alt":_vm.$t('imageUpload.uploadImage')}}),_c('div',{staticClass:"body-2 mt-2 white--text"},[_vm._v(_vm._s(_vm.$t('imageUpload.uploadImage')))]),(_vm.storageQuota && _vm.storageQuota.quota > -1)?_c('div',{staticClass:"body-3 grey--text caption mt-3"},[_vm._v(_vm._s(_vm.$t('plans.global.storageQuota'))+": "+_vm._s(_vm.calcAvailableStorage))]):_vm._e()]):_vm._e()]),(_vm.cropSrc)?_c('ImageCropper',{ref:"cropper",attrs:{"src":_vm.cropSrc,"min-canvas-height":_vm.minHeight,"view-mode":3,"drag-mode":_vm.dragMode,"container-style":{ height: '100%' },"aspect-ratio":4/3}},[_c('v-sheet',{staticClass:"image-upload--btn-wrapper d-flex flex-row justify-space-between",attrs:{"tile":"","color":"transparent","width":"100%"}},[_c('v-btn-toggle',{staticClass:"pa-0",attrs:{"mandatory":"","tile":"","height":"36"},model:{value:(_vm.dragMode),callback:function ($$v) {_vm.dragMode=$$v},expression:"dragMode"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"value":"move","min-width":"44","color":"primary","height":"36"}},on),[_c('v-icon',[_vm._v("mdi-cursor-move")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t('run.edit.move'))+" ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"value":"crop","min-width":"44","color":"primary","height":"36"}},on),[_c('v-icon',[_vm._v("mdi-crop")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t('run.edit.crop'))+" ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"value":"none","min-width":"44","color":"primary","height":"36"}},on),[_c('v-icon',[_vm._v("mdi-magnify-plus")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t('run.edit.zoom'))+" ")])],1),_c('div',[_c('v-btn',{staticClass:"pa-0",attrs:{"tile":"","color":"primary","min-width":"44"},on:{"click":_vm.emitSave}},[_c('v-icon',[_vm._v("mdi-check")])],1),_c('v-btn',{staticClass:"pa-0",attrs:{"tile":"","color":"secondary","min-width":"44"},on:{"click":_vm.cancel}},[_c('v-icon',[_vm._v("mdi-cancel")])],1)],1)],1)],1):_vm._e(),_c('v-fade-transition',[(hover && !!(_vm.source && !_vm.cropSrc))?_c('div',{staticClass:"d-flex flex-column transition-fast-in-fast-out v-card--reveal",staticStyle:{"height":"100%"}},[_c('div',[_c('label',{attrs:{"for":(_vm.inputId + "_2")}},[_c('input',{ref:"inputReplace",staticClass:"image-upload__file",attrs:{"id":(_vm.inputId + "_2"),"type":"file","accept":_vm.accept},on:{"change":_vm.onInput}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-6",attrs:{"icon":"","tag":"a","color":"white"}},on),[_c('v-icon',[_vm._v("mdi-sync")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('imageUpload.action.replaceImage')))])])],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(!_vm.disableDelete)?_c('v-btn',_vm._g({attrs:{"icon":"","color":"white"},on:{"click":_vm.remove}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('imageUpload.action.deleteImage')))])])],1),_c('div',{staticClass:"body-2 mt-6"},[_vm._v(" "+_vm._s(_vm.$t('imageUpload.editMessage'))+" ")]),(_vm.storageQuota && _vm.storageQuota.quota > -1)?_c('div',{staticClass:"body-3 grey--text caption mt-3"},[_vm._v(" "+_vm._s(_vm.$t('plans.global.storageQuota'))+": "+_vm._s(_vm.calcAvailableStorage)+" ")]):_vm._e()]):_vm._e()]),_c('v-fade-transition',[(_vm.isUploading)?_c('div',{staticClass:"d-flex flex-column transition-fast-in-fast-out v-card--reveal",staticStyle:{"height":"100%"}},[_c('v-progress-circular',{attrs:{"indeterminate":_vm.uploadProgress === -1,"value":(_vm.uploadProgress || 0) * 100,"color":"primary","width":"10","size":"100"}},[(_vm.uploadProgress !== -1)?_c('div',{staticClass:"white--text"},[_vm._v(" "+_vm._s(Math.round((_vm.uploadProgress || 0) * 100))+"% ")]):_vm._e()])],1):_vm._e()]),_c('v-expand-transition',[(_vm.dragOver > 0)?_c('div',{staticClass:"d-flex flex-column transition-fast-in-fast-out v-card--reveal pointer-events-none",staticStyle:{"height":"100%"}},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('imageUpload.editMessage'))+" ")])]):_vm._e()]),_vm._t("default")],2)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }