import Vue from 'vue'
import { tagSelectFilter } from '../../utils/core'

export default Vue.extend({
  filters: {
    tagSelectFilter
  },
  methods: {
    tagSelectFilter
  }
})
