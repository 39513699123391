var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fill-height content-padding"},[_c('Headline',{staticClass:"headline",attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.headlineText)+" ")]),_c('div',{staticClass:"d-flex",class:_vm.$vuetify.breakpoint.xs ? 'flex-column' : 'flex-row'},[_c('v-spacer'),(_vm.languageTags.length)?_c('v-autocomplete',{staticClass:"flex-grow-0 mb-6 pb-0 pt-2 pr-1",staticStyle:{"min-width":"200px"},attrs:{"clearable":"","items":_vm.languageTags,"label":_vm.$t('core.global.language'),"filter":_vm.tagSelectFilter,"item-text":"name","item-value":"identifier"},model:{value:(_vm.temporaryLanguage),callback:function ($$v) {_vm.temporaryLanguage=$$v},expression:"temporaryLanguage"}}):_vm._e(),(!_vm.$vuetify.breakpoint.xs)?_c('v-divider',{staticClass:"mb-10 mt-4 mx-4",attrs:{"vertical":""}}):_vm._e(),_c('SearchFilterBar',{staticClass:"flex-grow-0 mb-6 mx-1",attrs:{"search-value":_vm.searchValue,"view":_vm.selectedView},on:{"update:searchValue":function($event){_vm.searchValue=$event},"update:search-value":function($event){_vm.searchValue=$event},"update:view":function($event){_vm.selectedView=$event}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"can",rawName:"v-can",value:('learningpath-create'),expression:"'learningpath-create'"}],staticClass:"ml-4",attrs:{"color":"primary","fab":"","small":"","to":"/learningpath/create/basic"}},on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('core.action.create')))])])]},proxy:true}])})],1),_c('v-data-table',{class:{ 'fixed-table-layout': _vm.selectedView === 'tiles' },attrs:{"loading":_vm.loading > 0,"headers":_vm.computedHeaders,"items":_vm.presentedData,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"server-items-length":_vm.count,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:page":function($event){_vm.page=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row justify-space-between align-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticStyle:{"overflow":"hidden","text-overflow":"ellipsis","white-space":"nowrap","cursor":"pointer"},on:{"click":function($event){return _vm.handleRowClick(item)}}},on),[_vm._v(" "+_vm._s(item.name)+" "),(_vm.isFallbackLanguage(item, _vm.temporaryLanguage))?_c('v-chip',{staticClass:"ma-2",attrs:{"x-small":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-translate ")]),_vm._v(" "+_vm._s(_vm.isFallbackLanguage(item, _vm.temporaryLanguage))+" ")],1):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('run.action.openDetails')))])]),(_vm.$vuetify.breakpoint.xs)?_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"dark":_vm.$vuetify.theme.dark,"icon":""}},on),[_c('v-icon',[_vm._v(" mdi-dots-vertical ")])],1)]}}],null,true)},[_c('v-list',[(_vm.$permission.can(null, 'learningpath-update'))?_c('v-list-item',[_c('v-btn',{attrs:{"small":"","icon":"","to":("/learningpath/" + (item.id))}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-tune ")])],1)],1):_vm._e(),(_vm.$permission.can(null, 'learningpath-delete'))?_c('v-list-item',[_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.remove(item.id, item.name)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)],1):_vm._e()],1)],1):_vm._e()],1)]}},{key:"item.mandatory_level",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("learningPath.mandatoryLevels." + (item.mandatory_level))))+" ")]}},{key:"item.usergroups",fn:function(ref){
var item = ref.item;
return [_vm._l((_vm.getLearningPathTags(item, 'usergroup')),function(usergroup,index){return (index < 1)?_c('v-chip',{key:index,staticClass:"white--text",style:(index > 0 ? 'margin-left: -10px;' : ''),attrs:{"color":usergroup ? _vm.stringToColor(usergroup.identifier) : '',"small":""}},[_vm._v(" "+_vm._s(_vm.getUsergroupName(usergroup))+" ")]):_vm._e()}),(_vm.getLearningPathTags(item, 'usergroup').length > 1)?_c('v-chip',{staticStyle:{"margin-left":"-10px"},attrs:{"small":""}},[_vm._v(" +"+_vm._s(_vm.getLearningPathTags(item, 'usergroup').length - 1)+" ")]):_vm._e()]}},{key:"item.languages",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"white--text",attrs:{"small":"","color":_vm.stringToColor(item.languagecode)}},[_vm._v(" "+_vm._s(item.languagecode)+" ")]),_vm._l((_vm.getLearningPathTags(item, 'language')),function(language,index){return (index < 2 && language.identifier !== item.languagecode)?_c('v-chip',{key:index,staticClass:"white--text",style:(index > 0 ? 'margin-left: -10px;' : ''),attrs:{"color":language ? _vm.stringToColor(language.identifier) : '',"small":""}},[_vm._v(" "+_vm._s(language.identifier)+" ")]):_vm._e()}),(_vm.getLearningPathTags(item, 'language').length > 2)?_c('v-chip',{staticStyle:{"margin-left":"-10px"},attrs:{"small":""}},[_vm._v(" +"+_vm._s(_vm.getLearningPathTags(item, 'language').length - 2)+" ")]):_vm._e()]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.updatedAtDate ? _vm.$d(item.updatedAtDate, 'short') : ''))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"actions"},[(_vm.$permission.can(null, 'learningpath-create'))?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-3",attrs:{"small":"","icon":"","to":("/learningpath/" + (item.id) + "/basic")}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-tune ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('run.action.openDetails')))])])]:_vm._e(),(_vm.$permission.can(null, 'learningpath-delete'))?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.remove(item.id, item.name)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('run.action.delete')))])])]:_vm._e()],2)]}}])}),_c('FilterView',{attrs:{"filter-by":_vm.filterBy,"filter-options":_vm.filterOptions},on:{"update:filterBy":function($event){_vm.filterBy=$event},"update:filter-by":function($event){_vm.filterBy=$event}},model:{value:(_vm.showFilter),callback:function ($$v) {_vm.showFilter=$$v},expression:"showFilter"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }