











































































import { nameToIdentifier, objectHash, getTextForUserLanguage } from '@simpl/core/utils'

import { CREATE_LEARNING_PATH, GET_LEARNING_PATH, UPDATE_LEARNING_PATH } from '../graphql'

import LearningPathEditSidebar from '../components/LearningPathEditSidebar.vue'
import LearningPathEditBasic from '../views/LearningPathEditBasic.vue'
import LearningPathEditRuns from '../views/LearningPathEditRuns.vue'

import UserGroupTags from '@simpl/core/mixins/apollo/UserGroupTags'
import LanguageTags from '@simpl/core/mixins/apollo/LanguageTags'
import FileUpload from '@simpl/core/mixins/apollo/FileUpload'
import { EditableLearningPath } from '../types'
import { Query, Tag, Text, UpdateLearningPathInput } from '@simpl/core/types/graphql'
import mixins from 'vue-typed-mixins'

function createEmptyLearningPath (defaultLanguageCode: string): EditableLearningPath {
  return {
    created_at: null,
    updated_at: null,
    domain: null,
    languagecode: defaultLanguageCode,
    files: [],
    runs: [],
    properties: {},
    tags: [],
    texts: [],
    name: '',
    tagIds: []
  } as any
}

export type LearningPathEditUpdates = {
  lang: Record<string, LearningPathEditUpdates>
  name: string,
  [key: string]: any
}

export default mixins(UserGroupTags, LanguageTags, FileUpload).extend({
  name: 'LearningPathEdit',

  components: {
    LearningPathEditSidebar
  },

  props: {
    learningPathId: [String, Number],
    view: {
      type: String,
      default: 'basic'
    }
  },

  breadcrumbs () {
    const breadcrumbs: any[] = [{
      text: 'learningPath.global.headline',
      to: '/learningpaths'
    }]

    if (!this.id) {
      breadcrumbs.push({
        text: 'core.action.create',
        to: null
      })
    }
    if (
      this.id &&
      getTextForUserLanguage(this.learningPath)
    ) {
      breadcrumbs.push({
        title: getTextForUserLanguage(this.learningPath),
        to: null
      })
    }
    return breadcrumbs
  },

  data () {
    return {
      id: this.learningPathId,
      sidebarMini: false,
      showSidebar: true,

      loading: 0,

      updates: { lang: {} } as LearningPathEditUpdates,

      initialHash: null! as string,
      currentHash: null! as string,

      learningPath: (this.learningPathId
        ? null!
        : createEmptyLearningPath(this.$store.state.auth.user.languagecode)) as EditableLearningPath
    }
  },

  computed: {
    views (): ({ key: string, icon: string, component: any, disabled?: boolean })[] {
      return [{
        key: 'basic',
        icon: 'mdi-record-circle-outline',
        component: LearningPathEditBasic
      }, {
        key: 'contents',
        icon: 'mdi-folder',
        component: LearningPathEditRuns,
        disabled: !this.learningPath.tags.filter((tag: Tag) => tag!.category?.identifier === 'usergroup').length
      }]
    },

    canSave (): boolean {
      // eslint-disable-next-line
      return this.hasChanged && !!(this.id || this.learningPath.name || this.updates.lang[this.learningPath!.languagecode!]?.display_name)
    },

    hasChanged (): boolean {
      const hasUpdates = Object.keys(this.updates).length > 1 || Object.keys(this.updates.lang).length > 0
      return hasUpdates || (this.initialHash !== this.currentHash)
    }
  },

  watch: {
    learningPath: {
      deep: true,
      handler (v: object): void {
        this.currentHash = objectHash(v)
      }
    }
  },

  apollo: {
    learningPath: {
      query: GET_LEARNING_PATH,

      skip (): boolean {
        return !this.id
      },

      fetchPolicy: 'no-cache',

      loadingKey: 'loading',

      variables (): object {
        return { id: this.id }
      },

      update (data: Query): EditableLearningPath {
        const learningPath = data.learningPath!

        if (!learningPath) {
          this.$router.replace('/404')
          return null!
        }

        const retVal = {
          ...learningPath,
          name: getTextForUserLanguage(learningPath, learningPath.languagecode!) || '',
          tagIds: learningPath.tags!.map(t => `${t!.id}`),
          runs: learningPath.properties.runAgenda
            ? learningPath.properties.runAgenda.filter((runId: string) => learningPath.runs!.find(run => run!.id === runId)).map((runId: string) =>
              learningPath.runs!.find(run => run!.id === runId))
            : learningPath.runs
        }

        this.initialHash = this.currentHash = objectHash(retVal)

        return retVal as any
      }
    }
  },

  methods: {
    createSlug (identifier: string): string {
      let result = ''
      const characters = identifier.replace('-', '') + 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      const charactersLength = characters.length
      for (let i = 0; i < 10; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
      }
      return result
    },
    getMutationVariables (): UpdateLearningPathInput {
      const variables: UpdateLearningPathInput = {
        texts: {
          create: [],
          delete: []
        },
        runs: {
          sync: this.learningPath.runs!.map((r: any) => r!.id)
        },
        files: {
          sync: this.learningPath.files!.map((f: any) => f!.id)
        },
        tags: {
          sync: this.learningPath.tagIds
        }
      } as any

      if (!this.id) {
        // eslint-disable-next-line
        this.learningPath.name = this.updates.lang[this.learningPath.languagecode!].display_name
        this.learningPath.tagIds!.push(this.languageTags!.find(tag => tag.identifier === this.learningPath.languagecode!)!.id)
        variables.identifier = this.createSlug(nameToIdentifier(this.learningPath!.name!))
      }

      variables.mandatory_level = this.learningPath.mandatory_level
      variables.priority = this.learningPath.priority
      variables.languagecode = this.learningPath.languagecode

      if (this.learningPath.id) {
        variables.id = this.learningPath.id
      }

      for (const lang in this.updates.lang) {
        this.getMutationsVariablesForLang(this.updates.lang[lang], variables, lang)
      }

      this.deleteTextForDeletedLanguages(variables)

      if (this.learningPath.properties) {
        variables.properties = JSON.stringify(this.learningPath.properties)
      }

      return variables
    },

    getMutationsVariablesForLang (data: LearningPathEditUpdates, variables: UpdateLearningPathInput, language: string): void {
      for (const identifier in data) {
        if (data[identifier].length) {
          variables.texts!.create!.push({
            languagecode: language,
            identifier: identifier,
            text: data[identifier]
          })
        } else {
          const textId = this.learningPath.texts!
            .find((text: Text) => text!.identifier === identifier && text!.languagecode === language)?.id

          if (textId) variables.texts!.delete!.push(textId)
        }
      }
    },

    deleteTextForDeletedLanguages (variables: UpdateLearningPathInput) {
      if (!this.updates.deletedLanguages) return

      const languageCodes = this.updates.deletedLanguages
      const deletedTextIds = this.learningPath.texts!.filter((text: Text) => languageCodes.indexOf(text!.languagecode!) >= 0).map((text: any) => text!.id)

      deletedTextIds.forEach((id: string) => variables.texts!.delete!.push(id))
    },

    async cancel (): Promise<void> {
      await this.$router.push('/learningpaths')
    },

    async save (): Promise<void> {
      this.loading += 1
      let hasErrors: Boolean = false

      const result = await this.$apollo.mutate({
        mutation: this.id ? UPDATE_LEARNING_PATH : CREATE_LEARNING_PATH,

        variables: {
          data: this.getMutationVariables()
        }
      }).catch((error: Error) => {
        console.error(error.message, error.name)
        hasErrors = true
      })
      this.currentHash = this.initialHash

      if (!hasErrors && result) {
        if (!this.id) {
          await this.$router.replace(`/learningpath/${result.data.createLearningPath.id}/basic`)
          this.id = result.data.createLearningPath.id
        } else {
          await this.$apollo.queries.learningPath.refetch()
        }

        this.$notification.publish('bottom', {
          message: this.$t('core.message.savedChanges'),
          type: 'success',
          color: 'success'
        })
        this.loading -= 1

        this.updates = { lang: {} } as LearningPathEditUpdates
      } else {
        this.loading -= 1
      }
    },

    reloadLearningPath (): void {
      this.$apollo.queries.learningPath.refetch()
    }
  },

  async beforeRouteLeave (to: any, _from: any, next: (target?: any) => void) {
    if (!this.hasChanged || this.loading > 0) {
      next()
      return
    }

    const nextIsModuleEdit = to.name === 'base-management.module.edit'

    const userFeedback = await this.$confirm({
      message: nextIsModuleEdit ? this.$t('core.message.leavePage') : this.$t('core.message.unsavedChanges'),
      buttons: [{
        text: this.$t('core.global.yes'),
        type: 'outlined',
        answer: false
      }, {
        text: this.$t('core.global.no'),
        answer: true
      }]
    })

    if (!userFeedback) {
      await this.$apollo.queries.learningPath.refetch()
      next()
      return
    }

    next(false)
  }
}
)
