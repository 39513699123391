












































































































































import mixins from 'vue-typed-mixins'
import { Module, Run } from '@simpl/core/types/graphql'
import { getKeyvisualForTemporaryUserLanguage } from '@simpl/base-management/utils/keyvisual'
import { getTextForTemporaryUserLanguage, stringToColor } from '@simpl/core/utils'
import { TranslateResult } from 'vue-i18n'
import RunTypes from '../runs/mixins/RunTypes'
import ModuleFormats from '@simpl/core/mixins/utils/ModuleFormats'
import { PackageFormat } from '@simpl/core/utils/package-formats'

export default mixins(ModuleFormats).extend({
  name: 'ItemTile',

  mixins: [
    RunTypes
  ],

  props: {
    item: Object as () => Module | Run,
    to: String
  },

  computed: {
    keyVisualUrl (): string | null | undefined {
      return this.item.__typename!.substr(0, 3) === 'Run'
        ? getKeyvisualForTemporaryUserLanguage(this.item)?.url
        : this.item.keyvisual?.url
    },
    isModule (): boolean {
      return this.item.__typename === 'Module'
    },
    itemType (): TranslateResult {
      return this.isModule ? this.$t(`core.contentTypes.${this.item.type}`) : this.$t(`run.types.${this.item.type}`)
    },
    duration (): number | null | undefined {
      return (this.item as any).duration
    },
    moduleContentType (): PackageFormat | null {
      return this.isModule ? this.getModuleFormat(this.item as Module)! : null
    }
  },

  methods: {
    getTextForTemporaryUserLanguage,
    stringToColor
  }
})
