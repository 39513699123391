




















































































































































































































































import mixins from 'vue-typed-mixins'
import { DELETE_LEARNING_PATH, LIST_LEARNING_PATHS } from '../graphql'
import ItemTile from '../../components/ItemTile.vue'
import FilterView from '@simpl/core/components/FilterView.vue'

import { getTextForTemporaryUserLanguage, getTextForUserLanguage } from '@simpl/core/utils/text'
import StringToColor from '@simpl/core/mixins/utils/StringToColor'
import UserSettings from '@simpl/core/mixins/utils/UserSettings'
import LanguageTagSelectFilter from '@simpl/core/mixins/utils/TagSelectFilter'
import { stringToDate } from '@simpl/core/utils/core'
import { MUTATIONS } from '@simpl/auth/store/consts'
import { LearningPath, Query, Tag } from '@simpl/core/types/graphql'
import { DocumentNode } from 'graphql'
import TopicTags from '@simpl/core/mixins/apollo/TopicTags'
import { EditableLearningPath } from '../types'
import FallbackLanguageCheck from '@simpl/core/mixins/utils/FallbackLanguageCheck'

function remapEntry (entry: LearningPath): EditableLearningPath {
  return {
    ...entry,
    name: getTextForTemporaryUserLanguage(entry),
    updatedAtDate: stringToDate(entry.updated_at),
    tagIds: []
  }
}

export default mixins(TopicTags, StringToColor, LanguageTagSelectFilter, FallbackLanguageCheck, UserSettings('runListView', [
  'sortBy',
  'sortDesc',
  'selectedView',
  'itemsPerPage',
  'temporaryLanguage'
])).extend({
  name: 'LearningPathListView',

  components: { ItemTile, FilterView },

  breadcrumbs () {
    return [{
      text: 'learningPath.global.headline',
      to: null
    }]
  },

  data () {
    return {
      listQuery: LIST_LEARNING_PATHS,
      selectedView: 'list',
      temporaryLanguage: '',
      selectedTopic: '',
      topicFilter: {} as object,

      /** Displayed data */
      learningPaths: [] as EditableLearningPath[],
      loading: 0,

      /** Dialog control flow */
      dialog: false,
      selected: null! as EditableLearningPath,
      editing: false,

      /** Search and filter */
      searchValue: '',
      sortBy: [] as string[],
      sortDesc: false,
      count: 1,
      page: 1,
      itemsPerPage: 10,
      showFilter: false,
      filterBy: [] as any[]
    }
  },

  computed: {
    headlineText (): string {
      return this.$t('learningPath.global.headline') as string
    },
    buttonsVisible (): number {
      let count = 0
      if (this.$permission.can(null, 'module-view')) {
        count++
      }
      if (this.$permission.can(null, 'learningpath-update')) {
        count++
      }
      if (this.$permission.can(null, 'learningpath-delete')) {
        count++
      }
      if (this.$permission.can(null, 'evaluation-view')) {
        count++
      }
      return count
    },
    headers (): Record<string, any>[] {
      return [
        {
          text: this.$t('core.global.name'),
          value: 'name'
        }, {
          text: this.$t('user.global.userGroups'),
          value: 'usergroups'
        }, {
          text: this.$t('learningPath.global.mandatoryLevel'),
          value: 'mandatory_level'
        }, {
          text: this.$t('core.global.languages'),
          value: 'languages'
        }, {
          text: this.$t('core.global.updatedAt'),
          value: 'updated_at'
        }, {
          text: '',
          value: 'actions',
          class: 'separate-left',
          width: 120,
          sortable: false
        }]
    },
    presentedData (): LearningPath[] {
      return this.learningPaths.slice().map(remapEntry)
    },
    computedHeaders (): Record<string, any>[] {
      return this.headers.filter(header => !(this.$vuetify.breakpoint.xs && header.value === 'actions'))
    },
    languageTags (): (Tag & { name: string })[] {
      const filterTags = (tag: Tag) => tag.category!.identifier === 'language'

      return this.$store.state.auth.user.tags
        .filter(filterTags)
        .map((tag: Tag) => { return { ...tag, name: getTextForUserLanguage(tag) } })
    },
    filterOptions (): any[] {
      return []
    }
  },

  watch: {
    selectedTopic (v: number | null): void {
      if (v) {
        this.topicFilter = {
          name: 'tags:tag_id',
          values: [v]
        }
      } else {
        this.topicFilter = {}
      }
    },
    temporaryLanguage (v: string | null): void {
      this.$store.commit(`auth/${MUTATIONS.SET_TEMPORARY_LANGUAGE}`, { temporaryLanguage: v })
    }
  },

  apollo: {
    learningPaths: {
      query (): DocumentNode {
        return this.listQuery
      },

      fetchPolicy: 'cache-and-network',

      variables (): Record<string, any> {
        return {
          filter: {
            search: {
              query: this.searchValue,
              columns: ['identifier']
            },
            filterBy: [...this.filterBy, this.topicFilter]
          },
          orderBy: this.sortBy.map((key, index) => ({
            column: this.sortBy[index],
            order: this.sortDesc ? 'DESC' : 'ASC'
          })),
          page: this.page,
          first: this.itemsPerPage
        }
      },

      update (result: Query): LearningPath[] {
        const paginatorInfo = result.learningPaths!.paginatorInfo!
        const { total, currentPage, perPage } = paginatorInfo
        this.count = total
        this.page = currentPage
        this.itemsPerPage = perPage

        return result.learningPaths!.data
      },

      loadingKey: 'loading'
    }
  },

  methods: {
    updateSearchValue (v: string): void {
      this.searchValue = v
    },

    handleRowClick (item: LearningPath) {
      this.$router.push(`/learningpath/${item.id}/basic`)
    },

    async remove (id: string | number, name: string): Promise<void> {
      const answer = await this.$confirm({
        color: 'error',
        message: this.$t('core.message.deleteConfirm', [name]),
        buttons: [{
          text: this.$t('core.action.cancel'),
          type: 'outlined',
          answer: false
        }, {
          text: this.$t('core.action.delete'),
          color: 'error',
          answer: true
        }]
      })

      if (!answer) return

      this.loading += 1
      await this.$apollo.mutate({
        mutation: DELETE_LEARNING_PATH,
        variables: {
          id
        }
      })
      this.loading -= 1
      await this.$apollo.queries.learningPaths.refetch()
    },

    getLearningPathTags (learningPath: LearningPath, category: string): Tag[] {
      return learningPath.tags.filter((tag: Tag) => tag.category!.identifier === category)
    },
    getUsergroupName (usergroup: Tag): String {
      return getTextForUserLanguage(usergroup, 'display_name')
    }
  }
})
