
































































































import { getTextForUserLanguage } from '@simpl/core/utils'
import ImageUpload from '@simpl/core/components/uploader/ImageUpload.vue'
import FileUpload from '@simpl/core/mixins/apollo/FileUpload'
import GenericKeyvisuals from '../mixins/GenericKeyvisuals'
import { EditableLearningPath } from '../types'
import { getKeyvisual, hasCustomKeyvisuals, isGeneric, isFallback } from '../../utils/keyvisual'
import { File as GraphQLFile } from '@simpl/core/types/graphql'
import mixins from 'vue-typed-mixins'

export default mixins(FileUpload, GenericKeyvisuals).extend({
  name: 'EditKeyvisual',

  components: { ImageUpload },

  props: {
    learningPath: Object as () => EditableLearningPath,
    learningPathLanguages: Array
  },

  data () {
    return {
      apiUrl: process.env.VUE_APP_API_URL || '',

      selectedKeyvisual: null! as GraphQLFile,
      selectedLanguageVersion: this.learningPath.languagecode,
      selectOption: hasCustomKeyvisuals(this.learningPath, this.learningPathLanguages as any) ? 'custom' : 'generic'
    }
  },

  computed: {
    selectFromGallery (): boolean {
      return this.selectOption === 'generic'
    },
    selectedLanguageKeyvisual (): GraphQLFile | undefined | null {
      return getKeyvisual(this.learningPath, this.selectedLanguageVersion as string)
    },
    selectedLanguageKeyvisualUrl (): string | undefined | null {
      return (!isGeneric(this.selectedLanguageKeyvisual!)) ? this.selectedLanguageKeyvisual?.url : ''
    },
    selectOptions (): Record<string, any>[] {
      return [{
        text: this.$t('run.edit.selectFromGallery'),
        value: 'generic'
      }, {
        text: this.$t('run.edit.uploadKeyvisual'),
        value: 'custom'
      }, {
        text: this.$t('run.edit.uploadKeyvisualForLanguage'),
        value: 'custom-language',
        hidden: !hasCustomKeyvisuals(this.learningPath, this.learningPathLanguages as any) || this.learningPathLanguages.length <= 1
      }]
    },
    disableDelete (): boolean {
      return isGeneric(this.selectedLanguageKeyvisual as GraphQLFile) ||
        isFallback(this.learningPath, this.selectedLanguageVersion as string)
    }
  },

  watch: {
    selectOption (v) {
      if (v === 'custom') this.selectedLanguageVersion = this.learningPath.languagecode
    },
    selectedKeyvisual (v, o) {
      if (!o || !v) return

      if (!this.selectFromGallery) {
        this.$emit('change', v, this.selectedLanguageVersion)
      } else {
        this.$emit('change', v, this.learningPath.languagecode)
      }
    },
    genericKeyvisuals (v) {
      const keyvisual = getKeyvisual(this.learningPath, this.learningPath.languagecode as string)

      this.selectedKeyvisual = isGeneric(keyvisual)
        ? v.find((file: GraphQLFile) => file.id === keyvisual.id)
        : keyvisual
    }
  },

  methods: {
    getTextForUserLanguage,

    async uploadKeyVisual ({ blob }: { blob: Blob }, language: string) {
      this.uploadLanguageCode = language

      this.uploadFile = new File([blob], 'keyvisual', { type: blob.type })

      const uploadedFiles = await this.upload() as any

      if (!uploadedFiles) return

      this.selectedKeyvisual = uploadedFiles[0]
    }
  }
})
