import Vue from 'vue'
import { LIST_TAGS } from '../../graphql'
import { getTextForUserLanguage } from '../../utils'
import { Query, Tag } from '../../types/graphql'

export default Vue.extend({
  data: () => ({
    topicTags: [] as Tag[]
  }),

  computed: {
    topicTagIds (): string[] {
      return this.topicTags.map((item: { id: string }) => item.id)
    }
  },

  apollo: {
    topicTags: {
      query: LIST_TAGS,

      fetchPolicy: 'no-cache',

      variables (): object {
        return {
          first: 9999,
          filter: {
            filterBy: [{
              name: 'identifier',
              values: ['topic']
            }]
          }
        }
      },

      update (result: Query): any[] {
        return result.tagCategories!.data[0]!.tags!.slice().map((r: any) => ({
          ...r,
          name: getTextForUserLanguage(r)
        })).sort((a, b) => (a.name < b.name) ? -1 : 1)
      },

      error (error: Error) {
        console.error(error)
      }
    }
  }
})
