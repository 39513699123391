















































































































































































































































import ImageCropper from './ImageCropper'
import Vue from 'vue'
import { DomainQuotaItem, Query } from '../../types/graphql'
import { DOMAIN_STORAGE_QUOTA } from '@simpl/access-control/graphql'
import mixins from 'vue-typed-mixins'
import StorageQuota from '../../mixins/apollo/StorageQuota'

let id = 0

export default mixins(StorageQuota).extend({
  components: { ImageCropper },
  props: {
    canCancel: {
      type: Boolean,
      default: true
    },
    aspectRatio: {
      type: Number,
      default: undefined
    },
    backgroundColor: {
      type: String,
      default: 'rgba(0, 0, 0, 0.2)'
    },
    minHeight: [Number, String],
    maxHeight: [Number, String],
    height: [Number, String],
    lazySrc: String,
    src: String,
    accept: {
      type: String,
      default: 'image/*'
    },
    imageType: {
      type: String,
      default: 'image/jpeg'
    },
    quality: {
      type: Number,
      default: 0.8
    },
    id: String,
    isUploading: Boolean,
    uploadProgress: Number,
    disableDelete: Boolean
  },

  data () {
    return {
      source: this.src as string | null | undefined,
      inputId: this.id,
      cropSrc: null! as string | ArrayBuffer | null,
      dragOver: 0,
      dragMode: 'move'
    }
  },

  watch: {
    src () {
      this.source = this.src
    }
  },

  created () {
    if (!this.id) {
      id += 1
      this.inputId = `image_upload_${id}`
    }
  },

  methods: {
    showPreview (file: File) {
      if (!file) {
        this.cropSrc = null
        return
      }

      const reader = new FileReader()

      reader.onload = (e) => {
        this.cropSrc = e.target!.result
      }

      reader.readAsDataURL(file)
    },

    onInput ($event: Event) {
      const target = ($event.target as any)
      const file = target.files[0]
      this.showPreview(file)
      target.value = ''
    },

    /** @type {DragEvent} $e */
    onDrop ($event: DragEvent) {
      this.dragOver = 0
      const file = $event!.dataTransfer!.files[0]
      this.showPreview(file)
      $event.preventDefault()
    },

    emitSave () {
      (this.$refs.cropper as any).cropper.getCroppedCanvas().toBlob((blob: any) => {
        this.source = (this.$refs.cropper as any).cropper.getCroppedCanvas().toDataURL()
        this.cropSrc = null
        this.$emit('change', {
          blob,
          source: this.source
        })
      }, this.imageType, this.quality)
    },

    cancel () {
      this.source = this.src
      this.cropSrc = null
    },

    remove () {
      this.source = null
      this.$emit('remove')
    }
  }
})

