




























































































































































































































































import SortableJS from 'sortablejs'

import ItemTile from '../../components/ItemTile.vue'
import CoreSearchFilterBar from '@simpl/core/components/SearchFilterBar.vue'

import mixins from 'vue-typed-mixins'
import StringToColor from '@simpl/core/mixins/utils/StringToColor'
import ModuleFormats from '@simpl/core/mixins/utils/ModuleFormats'

import { EditableLearningPath } from '../types'
import { Run, Tag } from '@simpl/core/types/graphql'
import { stringToDate } from '@simpl/core/utils/core'

import { LIST_RUNS } from '../../runs/graphql'
import TopicTags from '@simpl/core/mixins/apollo/TopicTags'
import { getTextForTemporaryUserLanguage } from '@simpl/core/utils'
import { TagWithName } from '@simpl/core/types/extended-types'

export default mixins(StringToColor, ModuleFormats, TopicTags).extend({
  name: 'LearningPathEditRuns',

  components: {
    CoreSearchFilterBar,
    ItemTile
  },

  mixins: [],

  props: {
    learningPath: Object as () => EditableLearningPath,
    userGroupTags: Array as () => TagWithName[],
    userGroupTagIds: Array
  },

  data () {
    const featureMode = this.$store.getters['runs/featureMode']
    return {
      featureMode,
      runs: [] as Run[],
      // displayedRuns: [] as Run[],
      showRunList: false,

      selectedRuns: [] as any[],
      selectedRunsInDrawer: [] as any[],

      loadingRuns: 0,
      limit: 6,

      selectedTopic: '',
      topicFilter: {} as object,

      /** Search and filter */
      searchValue: '',
      sortBy: ['name'] as string[],
      sortDesc: false,
      count: 1,
      page: 1,
      itemsPerPage: 99999,
      showFilter: false,
      filterBy: [] as any[]
    }
  },

  computed: {
    headers (): Record<string, any>[] {
      const headers = [{
        text: '',
        value: 'drag-indicator'
      }, {
        text: this.$t('core.global.name'),
        value: 'name'
      }, {
        text: this.$t('core.global.type'),
        value: 'type'
      }, {
        text: '',
        value: 'actions',
        class: 'separate-left',
        width: 110
      }]
      return headers
    },

    isConsecutive: {
      get (): boolean {
        return (this.learningPath.properties.consecutive) ? this.learningPath.properties.consecutive : false
      },
      set (v: boolean) {
        this.$set(this.learningPath.properties, 'consecutive', v)
      }
    },

    displayedRuns (): Run[] {
      if (this.selectedTopic) {
        return this.runs.filter((r: Run) => r.tags.filter((t: Tag) => t!.identifier === this.selectedTopic && t!.category!.identifier === 'topic').length > 0)
      } else {
        return this.runs
      }
    },

    learningPathRunIds (): string[] {
      return this.learningPath!.runs!.map(run => run!.id)
    },

    headlineText (): string {
      return this.$t('run.global.' + this.featureMode) as string
    },

    selectedUserGroupTags (): string[] {
      return this.learningPath.tagIds!.filter((id: string) => this.userGroupTagIds.indexOf(id) > -1)
    }
  },

  apollo: {
    runs: {
      query: LIST_RUNS,

      loadingKey: 'loadingRuns',

      variables (): object {
        return {
          filter: {
            search: {
              query: this.searchValue,
              columns: ['identifier']
            },
            filterBy: [...this.filterBy, {
              name: 'tags:tag_id',
              values: this.learningPath.tags.filter((tag: Tag) => tag!.category!.identifier === 'usergroup').map((tag: Tag) => tag.id)
            }]
          },
          orderBy: this.sortBy.map((key, index) => ({
            column: this.sortBy[index],
            order: this.sortDesc ? 'DESC' : 'ASC'
          })),
          page: this.page,
          first: this.itemsPerPage
        }
      },

      update (data) {
        const values = data.runs.data

        this.initSortableTable()

        return values
      }
    }
  },

  methods: {
    getTextForTemporaryUserLanguage,
    stringToDate,
    toggleSelectedRuns (val: string, toggle?: any) {
      toggle && toggle()
      if (this.learningPath!.runs!.filter(run => run!.id === val).length > 0) {
        this.remove(val)
      } else {
        this.add(val)
      }
    },
    add (id: string) {
      const run = this.runs.find(r => r.id === id)
      if (run) {
        this.learningPath!.runs.push(run)
        this.$set(this.learningPath.properties, 'runAgenda', this.learningPath!.runs!.map(r => r!.id))
      }
    },
    remove (id: string) {
      this.learningPath!.runs = this.learningPath!.runs!.filter(m => m!.id !== id)
      if (this.learningPath!.properties.runAgenda) {
        this.learningPath!.properties.runAgenda.splice(this.learningPath!.properties.runAgenda.indexOf(id), 1)
      }
    },
    getRunTags (run: Run, category: string) {
      return run.tags!.filter(tag => tag!.category!.identifier === category)
    },
    updateRuns (v: any[]) {
      this.$nextTick(() => {
        this.learningPath!.runs = v.reduce((acc, c) => {
          acc.push(this.runs.find(r => r.id === c))
          return acc
        }, [])
        this.$set(this.learningPath.properties, 'runAgenda', this.learningPath!.runs!.map(r => r!.id))
      })
    },
    initSortableTable () {
      if ((this as any)._sortable) {
        (this as any)._sortable.destroy()
      }

      this.$nextTick(() => {
        const table: HTMLElement | null =
          (this.$el as HTMLElement).querySelector('.v-data-table tbody')
        if (!table) return;
        (this as any)._sortable = SortableJS.create(table, {
          onEnd: ({ newIndex, oldIndex }: any): void => {
            const item = this.learningPath!.runs!.splice(oldIndex!, 1)[0]
            this.learningPath!.runs!.splice(newIndex!, 0, item)

            this.$set(this.learningPath.properties, 'runAgenda', this.learningPath!.runs!.map(m => m!.id))
          }
        })
      })
    }
  }
})
