import Vue from 'vue'
import { UPLOAD_FILE } from '../../graphql'
import { File as UploadedFile, UploadFileInput } from '../../types/graphql'
import axios from 'axios'

const cancelToken = axios.CancelToken.source()

export default Vue.extend({
  data: () => ({
    isUploading: false,
    uploadProgress: 0,
    uploadLanguageCode: null as string | null,
    uploadFile: null as File | null,
    uploadContentCategory: null as string | null
  }),

  methods: {
    getFileUploadMutationVariables (): UploadFileInput {
      const variables: UploadFileInput = {
        selected_file: this.uploadFile,
        name: this.uploadFile!.name,
        target_type: this.uploadContentCategory || 'keyvisual_run'
      }

      if (this.uploadLanguageCode) {
        variables.languagecode = this.uploadLanguageCode
      }

      return variables
    },

    async upload (): Promise<UploadedFile[] | null> {
      this.isUploading = true
      try {
        const result = await this.$apollo.mutate({
          mutation: UPLOAD_FILE,

          variables: {
            file: this.getFileUploadMutationVariables()
          },

          context: {
            fetchOptions: {
              onUploadProgress: (progress: ProgressEvent) => {
                if (progress.lengthComputable) {
                  this.uploadProgress = progress.loaded / progress.total
                } else {
                  this.uploadProgress = -1
                }
              },
              cancelToken: cancelToken.token
            }
          }
        })

        this.isUploading = false

        return result.data.uploadFiles
      } catch (e) {
        this.isUploading = false

        Vue.util.warn(e.message, this)
        return null
      }
    }
  }
})
