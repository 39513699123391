import Vue from 'vue'
import { DomainQuotaItem, Query, Tag } from '../../types/graphql'
import { DOMAIN_STORAGE_QUOTA } from '@simpl/access-control/graphql'

export default Vue.extend({
  data: () => ({
    storageQuota: null! as DomainQuotaItem
  }),

  computed: {
    calcAvailableStorage (): string {
      if (this.storageQuota.available / 1024 / 1024 < 1) {
        if (this.storageQuota.available / 1024 < 100) {
          return `${this.storageQuota.available} kB`
        }
        return `${(this.storageQuota.available / 1024).toFixed(2)} MB`
      }
      return `${(this.storageQuota.available / 1024 / 1024).toFixed(2)} GB`
    }
  },

  apollo: {
    storageQuota: {
      query: DOMAIN_STORAGE_QUOTA,

      fetchPolicy: 'no-cache',

      update (result: Query): DomainQuotaItem {
        return { ...result.domainQuotas.storage }
      },

      error (error: Error) {
        console.error(error)
      }
    }
  }
})
